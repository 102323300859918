body{
    background-color: #F8E367;
}
.container{
    padding: 1.5 rem;
    display: flex;
}
.logo{
    display: flex;
    align-items: center;
    flex: 0.95;
}
.logo img{
    width: 40px;
    height: 40px;
}
.logo span{
    font-weight: 600;
    font-size: 30px;
}
.right{
    display: flex ;
    gap:32px ;
    align-items: center;
    justify-content: center;
}
.menu{
    padding: 8px;
    margin-right: 10px;
    display: flex ;
    gap:32px ;
    font-weight: 500;
    text-decoration: none;
    list-style: none;
}
.menu >li:hover{
  color:#fe956f ; 
  cursor: pointer;
}
