.s-container{
  padding-top: 120px;
  position: relative;
}
.s-container .swiper{
  width: 70%;
  height: 192 px;
  position: unset;
}
.s-container .swiper-slide{
  background-color: white;
  display: flex;
  border-radius: 10px;
  padding: 1rem;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.left-s{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  margin: 5px;
}
.name{
  display: flex;
  flex-direction: column;
}
.name>:nth-child(1)
{
  font-size: 1.4rem;
  font-weight: 600;
}
.name>:nth-child(2)
{
  font-size: 0.9rem;
  margin-top: 10px;
}
.left-s>:nth-child(2)
{
  font-weight: bold;
  font-size: 2rem;
  font-size: 1.4rem;
  margin-top: 20px;
}
.left-s>:nth-child(3)
{
  font-size: 0.8rem;
  border: 1px solid black;
  padding: 5px 10px ;
  width: max-content;
  border-radius: 15px;
  margin-top: 50px;
}
.left-s button:hover{
  cursor: pointer;
}
.img-p{
  transform: rotate(-20deg);
  position: absolute;
  right: 0;
  width: 40%;
  height: 100%;
  bottom: -20;
}