.containerP{
    padding: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    justify-content: center;
}
.containerP>:nth-child(1){
    position: absolute;
    width: 8rem;
    left: 30%;
    top:-3rem;
}
.productsP{
   display: grid;
   width: 90%;
   grid-template-columns: 25% auto; 
}
.menuP{
   list-style: none;
   display: flex;
   flex-direction: column;
   gap: 2rem;
   font-weight: 500;
   font-size: 1.3rem; 
}
.menuP>li:hover{
cursor: pointer;
color:#fe956f ; 
}
.list{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
    padding: 10px;
    height: 25rem;
    overflow-y: scroll;
    grid-gap:2rem;
    justify-content:space-between ;
}
.product-pp{
    width: 15rem;
    height: 8rem;
    background-color: white;
    position: relative;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    border-radius: 1rem;
}
.product-pp>img{
    top:3rem;
    width: 6rem;
    height: 10rem;
}
.left-ss{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2px;
    margin: 5px;
    
  }
  .left-ss>:nth-child(2)
  {
    font-weight: 700;
  }
  .left-ss>:nth-child(3)
{
  font-size: 0.8rem;
  border: 1px solid black;
  padding: 5px 10px ;
  width: max-content;
  border-radius: 15px;
  margin-top: 20px;
  cursor: pointer;
}