.cFooterWrapper{
width:80%;
margin-top: 5rem;
align-items: center;
margin-bottom: 2rem;
}
.cFooterWrapper hr{
    width: 100%;
    height: 1px;
    border: none;
    margin-top: 1rem;
    background-color: white;
}
.cFooter{
    display: flex;
   width: 100%;
   justify-content: space-between;
   gap: 10rem;

}
.logo{
    display: flex;
    align-items: flex-start;
    gap:1rem;
}
.logo>img{
width:2.5rem;
height:2.5rem;
}
.logo>span{
font-weight: 600;
font-size: 1.5rem;
width: 20px;
}
.block{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 12rem;
}
.detail{
    display: flex;
    flex-direction: column;
    width: inherit;
    font-size: 0.7rem;
    gap: 1rem;
}
.detail>:nth-of-type(1)
{
   font-weight: 500;
   font-size: 1.2rem; 
   font-style: normal;
}
.pngLine:hover{
    cursor: pointer;

}
.pngLine{
    display: flex;
    gap: .2rem;
    align-items: center;
}
