.virtual{
  margin-top: 6rem;
  margin-bottom: 6rem;
  padding: 2rem;
  display: flex;
  justify-content: space-between;

}
.left{
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 2rem;
}
.left img{
    width: 200px;
    float: left;
    margin-left:-40px;
    margin-top: -20px;
}
.left>:nth-child(1)
{
    font-size: 2rem;
    text-transform: uppercase;
    display: block;
    width: 20rem;
}
.left>:nth-child(2)
{
    font-size: 1rem;
    text-transform: uppercase;
    display: block;
    width: 20rem;
}
.left>:nth-child(3)
{
    font-size: 1.6rem;
   font-weight: 10rem;
   font-weight: 500;
}
.wrapperImg{
    width: 30rem;
}