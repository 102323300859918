.cart-items {
    
    height: auto;
    padding-left: 30px ;
    position:relative;
  }
  
  .cart-items .cart-details {
    width: 20%;
  }
 
  .cart-items .cart-total {
    width: 30%;
    margin-top: 30px;
    margin-left: 30px;
    height: 130px;
  }
  .cart-list {
    border-radius: 10px;
    background:#f2f2f2;
    margin-top: 30px;
  }
  .cart-items .img {
    width: 150px;
    height: 150px;
  }
  .cart-items img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .cart-items .cart-details{
    display: inline;
  }
  .cart-items .cart-details h3 {
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
  }
  .cart-items .cart-details h4 {
    padding: 10px;
    font-size: 15px;
    font-weight: 400;
    margin-top: 50px;
    color:black;
  }
  .cart-items h4 span {
    color:black;
    margin-left: 20px;
    font-weight: 500;
  }
  .removeCart {
    background: none;
    font-size: 25px;
    text-align: right;
    margin-right: 10px;
  }
  .cartControl {
    margin-top: 50px;
  }
  .cartControl button {
    width: 40px;
    height: 40px;
    margin: 10px;
    border-radius: 5px;
    font-size: 20px;
    margin-left: 250px;
    bottom: 0;
  }
  .incCart {
    background: none;
    border: 1px solid rgb(3 0 71 / 9%);
    color: black;
   
  }
  .desCart {
    background: none;
    border: 1px solid rgb(3 0 71 / 9%);
    color: black;

  }
  .cart-total h4 {
    font-size: 15px;
    font-weight: 400;
  }
  .cart-total h3 {
   padding: 6px;
    font-size: 20px;
    font-weight: 500;
    color: black;
  }
  .cart-total h2 {
    font-size: 18px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(3 0 71 / 9%);
    padding-bottom: 10px;
    color:black;
  }
  .no-items {
    color:black;
    font-size: 18px;
    margin-top: 30px;
    height: 130px;
  }