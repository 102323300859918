.grid{
    padding: 40px;
    text-align: center;
    display: flex;
    justify-content: space-between;
 
}
 .img {
    margin: auto;
    width: 70px;
    height: 70px;
    line-height: 70px;
    margin-bottom: 25px;
  }

  .img i {
    font-size: 25px;
  
  }
   .product-w {
    background: #fff;
    padding: 10px;
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    margin: 10px;
  }
   .product-w h3 {
    font-weight: 500;
  }
  .product-w p{
    font-size: 15px;
    margin-top: 20px;
    color: grey;
  }