.test{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
}
.wrapper-tests{
  display: grid;
  align-items: flex-end;
  width: 100%;  
  grid-template-columns: 1fr 2fr 1fr;
}
.wrapper-tests>img{
    width:25rem;
    margin-left: 120px;
    justify-content: center;

}
.container-test{
    display: flex;
    flex-direction: column;
    grid:1rem;
}
.container-test>:nth-child(1){
   font-size: 2rem;
   text-transform: uppercase;
   display: block;
   font-weight: bold;
}
.container-test>:nth-child(2){
    font-size: 1rem;
    text-transform: uppercase;
    display: block;
 }
 .wrapper-tests>:nth-child(3)
 {
    text-align: right;

 }
 .wrapper-tests>:nth-child(3)
 {
    text-align: right;

 }
 .container-hero>:nth-child(1){
    font-size: 2rem;
    text-transform: uppercase;
    display: block;
    font-weight: bold;
 }
 .container-hero>:nth-child(2){
    font-size: 1rem;
    text-transform: uppercase;
    display: block;
 }
.carousel{
    width: 80%;
}
.tCarousel{
  padding: 3rem;  
}
.tests{
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    border-radius:0.5rem ;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap:1rem ;
    box-shadow: 0rem 1rem 3rem -50px #7d7d7d;
   
}
.tests>span:nth-child(1){
   font-size: 0.8rem;
}
.tests>span:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
 }