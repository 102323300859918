.sub{
margin-top: 20px;

display: flex;
height: 100px;
background-color: aliceblue;
justify-content:flex-start
}

.info{
    margin-left: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.info>span:nth-child(1)
{
    color: red;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
}
.info>span:nth-child(2)
{
   
    font-weight: 400;
}
.send {
    margin-top: 20px;
    margin-left: 600px;
  display: inline-block;
}
.send input{
    border: 2px solid #555;
    height: 50px;
    width: 400px;
    border-radius: 4px;
}
.send .btn{
   
   border-radius: 2px;
  color: white;
  background-color:black ;
 width: 100px;
 padding: 17px;

}