.containerH{
    padding :0rem 2rem ;
    display: grid;
    grid-template-columns: 1fr 3rf 1fr;
}
.wrapper{
    display: flex;
    align-items:baseline;
    justify-content: center;
}
.wrapper img{
    width:480px;
    position: absolute;
    bottom: 200px;
}
.blueCircle{
   width: 30rem;
   height: 30rem;
   border-radius: 50%;
   z-index: -99; 
   position: absolute;
   bottom: 200px;
   background-color: #29b6f6;
}
.h_sides{
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.text1{
   padding-top: 60px;
    text-transform: uppercase;
    font-size:24px ;
    width: min-content;
    display: block;
    font-weight: 600;
}
